<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100 text-uppercase">
    <h3 *ngIf="workspaceForm.get('enterprise_id').value != null">{{'manager_add_recruiting_space' | translate}}</h3>
    <h3 *ngIf="workspaceForm.get('enterprise_id').value == null">{{'manager_add_license' | translate}}</h3>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body p-3">
  <form [formGroup]="workspaceForm" class="form-display-errors" novalidate>
    <div class="row form-group">
      <div class="col-12">
        <label class="label-logon" for="name">{{'manager_workspace_name' | translate}}</label>
        <input type="text" formControlName="name" class="form-control" id="name"
               placeholder="{{'manager_workspace_name' | translate}}">
      </div>
    </div>
    <div class="text-center">
      <button [disabled]="workspaceForm.invalid" type="submit" class="btn btn-logon btn-logon-lg mt-2 py-2 w-50"
              (click)="confirm()">
              <span>{{'manager_add_workspace_modal_add' | translate}}</span>
      </button>
    </div>
  </form>
</div>






