<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100 text-uppercase">
    <h3>{{'manager_email_delegation' | translate}}</h3>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body p-3">
  <form [formGroup]="delegateForm" class="form-display-errors" novalidate>
    <div class="row form-group">
      <div class="col-12">
        <label class="label-logon" for="inputEmail">{{'manager_account_email' | translate}}</label>
        <input type="email" formControlName="email" class="form-control" id="inputEmail" placeholder="{{'manager_email' | translate}}">
        <div class="mt-1" *ngIf="errors.length && !(delegateForm.get('email').hasError('required') && delegateForm.get('email').touched)">
          <span class="text-danger pl-2 span-font" *ngFor="let error of errors">{{error}}</span>
        </div>
        <span class="text-danger pl-2" *ngIf="delegateForm.get('email').hasError('required') && delegateForm.get('email').touched">{{'manager_email_required' | translate}}</span>
      </div>
    </div>
    <div class="text-center">
      <button type="submit" class="btn btn-logon mt-2 py-2" (click)="sendToEmail()" [disabled]="job.delegate_user_id">
        {{'manager_delegate_to_email' | translate}} <img src="assets/icons/arrow-right.svg" class="mb-1 ml-1">
      </button>
    </div>
  </form>
</div>
