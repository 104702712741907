<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100">
    <!-- <h2>{{'manager_landing_kontact' | translate}}</h2> -->
    <h3 class="text-center" style="margin-bottom: 4px;"><i style="color: white;" class="bi bi-envelope-fill"></i></h3>
    <h5 class="pt-0 text-center">{{'manager_modal_header_contact' | translate}}</h5>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>

<div class="modal-body p-3">
  <form [formGroup]="contactForm" class="form-display-errors" novalidate>
    <div class="row form-group">
      <div class="col-12 col-sm-6">
        <label class="label-logon" for="inputName">{{'manager_your_name' | translate}}<span class="text-danger">*</span></label>
        <input type="text" formControlName="full_name" class="form-control" id="inputName" placeholder="{{'manager_your_name' | translate}}">
      </div>
      <div class="col-12 col-sm-6">
        <label class="label-logon" for="company_name">{{'manager_company_name' | translate}}</label>
        <input type="text" formControlName="company_name" class="form-control" id="company_name" placeholder="{{'manager_company_name' | translate}}">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-12 col-sm-6">
        <label class="label-logon" for="inputEmail">{{'manager_email' | translate}}<span class="text-danger">*</span></label>
        <input type="email" formControlName="email" class="form-control" id="inputEmail" placeholder="{{'manager_email' | translate}}">
      </div>
      <div class="col-12 col-sm-6">
        <label class="label-logon" for="phone_number">{{'manager_phone_number' | translate}}</label>
        <input type="text" formControlName="phone" class="form-control" id="phone_number" placeholder="{{'manager_phone_number' | translate}}">
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label class="label-logon" for="subject">{{'manager_subject' | translate}} <span class="text-danger">*</span></label>
        <input type="text" formControlName="subject" class="form-control" id="subject" placeholder="{{'manager_subject' | translate}}">
      </div>
      <div class="col-12 form-group">
        <label class="label-logon" for="message">{{'manager_message' | translate}} <span class="text-danger">*</span></label>
        <textarea formControlName="message" class="form-control" style="height: 110px" id="message"></textarea>
      </div>
    </div>
    <button type="submit" class="btn btn-logon btn-logon-lg mt-2 py-2 w-100" (click)="confirm()">
      {{'manager_send' | translate}} <img src="assets/icons/arrow-right.svg" class="mb-1 ml-1">
    </button>
  </form>
</div>

