<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100 text-uppercase">
    <h5>{{'manager_user_invitation' | translate}}</h5>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body p-3">
  <form *ngIf="!invitationSuccessfullySent" [formGroup]="inviteForm" class="form-display-errors" novalidate>
    <div>
      <label class="label-logon" for="inputEmail">{{'user email' | translate}}</label>
      <input type="email" formControlName="email" class="form-control" id="inputEmail"
              placeholder="{{'manager_email' | translate}}">
    </div>
    <div *ngIf="errors.length && !(inviteForm.get('email').hasError('required') && inviteForm.get('email').touched)">
      <span class="text-danger pl-2 span-font" *ngFor="let error of errors">{{error}}</span>
    </div>
    <div *ngIf="inviteForm.get('email').hasError('required') && inviteForm.get('email').touched" class="text-danger text-center mt-2">
      {{'manager_email_required' | translate}}
    </div>  
    <div *ngIf="_authService?.getCurrentWorkspace()?.enterprise_id == null" class="text-muted text-center mt-2">
      {{'manager_price_for_invite_workspace' | translate:{price: price} }}
    </div>
    <div class="mt-1" *ngIf="invitationFailed">
      <div class="text-muted">{{'manager_user_invitation_failed' | translate}}</div>
    </div>
    <div class="text-center mt-2">
      <button type="submit" class="btn btn-logon mt-2 py-2" (click)="sendToEmail()">
        {{'manager_invite_user' | translate}} <img src="assets/icons/arrow-right.svg" class="mb-1 ml-1">
      </button>
    </div>
  </form>
  <div *ngIf="invitationSuccessfullySent">
    <div class="text-muted">{{'manager_user_invitation_success' | translate}}</div>
  </div>
</div>

