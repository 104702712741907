<!-- START navbar only visible on max-width:649px -->
<div class="mobile-nav-container">
  <div style="display:flex;height:100%;width:100%;">
    <img routerLink="/" style="z-index: 99;width: 120px;"
         src="/assets/2022_03_16_justapplai_quer_weiß_Markenkennzeichnung_SVG.svg" alt="LOGON"
         class=" d-lg-inline img-fluid">
    <div id="justapplai"></div>
    <button (click)="onHamburgerClick()" class="hamburger">
      <div class="bar"></div>
    </button>

    <div class="mobile-nav">
      <div id="mobile-nav-items">
        <div class="content-items">

          <div class="text-center dropdown workspace-dropdown">
            <select class="form-control select" *ngIf="userWorkspaces?.length != 0"
                    (change)="changeWorkspace($event?.target?.value)">
              <option
                [class.active]="userWorkspace?.workspace?.id == selectedWorkspace?.id"
                *ngFor="let userWorkspace of userWorkspaces;let i =index"
                [value]="userWorkspace.workspace?.id"
                [selected]="selectedWorkspace?.id == userWorkspace?.workspace?.id">{{userWorkspace?.workspace?.name}}</option>
              <optgroup *ngFor="let enterprise of enterprises" [label]="enterprise?.name">
                <option *ngFor="let workspace of enterprise.workspaces;"
                        [selected]="selectedWorkspace.id == workspace.id"
                        [value]="workspace.id">{{workspace.name}}</option>
              </optgroup>
            </select>
          </div>
          <a (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/dashboard')">
            <i class="bi bi-bar-chart"></i>
            <span class="links-name">{{'manager_dashboard' | translate}}</span>
          </a>
          <a (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/jobs')"
             [hasPermission]="'JOB'">
            <i class="bi bi-briefcase"></i>
            <span class="links-name">{{'manager_my_job_ads' | translate}}</span>
          </a>
          <a (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/candidates')"
             class="text-decoration-none"
             [hasPermission]="'CANDIDATES'">
            <i class="bi bi-person-plus"></i>
            <span class="links-name">{{'manager_candidates' | translate}}</span>
          </a>

          <a (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/media')"
             [hasPermission]="'MEDIA'">
            <i class="bi bi-card-image"></i>
            <span class="links-name">{{'Media' | translate}}</span>
          </a>

          <a (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/license')"
             [hasPermission]="'LICENSE'">
            <i class="bi bi-currency-dollar"></i>
            <span class="links-name">{{'manager_license' | translate}}</span>
          </a>

          <a
            (click)="onMobileNavItemClick('/admin/workspaces/' + authService.getCurrentWorkspaceId() + '/manage')"
            *ngIf="canManageWorkspace">
            <i class="bi bi-building"></i>
            <span class="links-name">{{'manager_manage_workspace' | translate}}</span>
          </a>

          <hr style="width: 250px;margin-top: 0;margin-bottom: 12px;opacity: 0.5;" class="text-white bg-white">

          <a (click)="onMobileNavItemClick('/admin/statistic')" [hasPermission]="'STATISTIC'">
            <i class="bi bi-graph-down"></i>
            <span class="links-name">{{'manager_statistic' | translate}}</span>
          </a>

          <a (click)="onMobileNavItemClick('/admin/users')" [hasPermission]="'USER'">
            <i class="bi bi-people"></i>
            <span class="links-name">{{'manager_statistic_users' | translate}}</span>
          </a>
          <a (click)="onMobileNavItemClick('/admin/roles')" [hasPermission]="'ROLE'">
            <i class="bi bi-person-lines-fill"></i>
            <span class="links-name">{{'manager_roles' | translate}}</span>
          </a>

          <a (click)="onMobileNavItemClick('./vouchers')" [hasPermission]="'VOUCHER'">
            <i class="bi bi-gift"></i>
            <span class="links-name">{{'manager_vouchers' | translate}}</span>
          </a>
          <a (click)="onMobileNavItemClick('./feed-config')" [hasPermission]="'FEED'">
            <i class="bi bi-gear-wide"></i>
            <span class="links-name">{{'manager_feed' | translate}}</span>
          </a>

          <a (click)="onMobileNavItemClick('/admin/workspaces')" [hasPermission]="'WORKSPACE'">
            <i class="bi bi-person-bounding-box"></i>
            <span class="links-name">{{'manager_workspaces' | translate}}</span>
          </a>
          <a (click)="onMobileNavItemClick('/admin/settings')" [hasPermission]="'SETTINGS'">
            <i class="bi bi-gear"></i>
            <span class="links-name">{{'manager_settings' | translate}}</span>
          </a>

          <a style="margin-top: 40px;" (click)="logout()">
            <i style="color: #fff;" class="bi bi-box-arrow-left"></i>
            <span class="links-name">{{'manager_logout' | translate}}</span>
          </a>
          <div style="display: flex;width: 100%;justify-content: center;margin-top: 15px;">
            <div style="display: flex;min-width: 230px;justify-content: center;">
              <div style="display: flex;">
                <img [src]="authService.getUser()?.avatar_url || 'assets/icons/profile-white.png'"
                     class="mr-2 admin-profile-image">
                <div
                  style="color: #fff; line-height: 21px;">{{authService.isLoggedIn() ? authService.getUserName() : ' '}}</div>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 100%;display:flex;justify-content: center;margin-top: 41px;">
          <div style="position:relative;min-width: 230px;">
            <div class="footer-links mb-2" style="display: flex;">
              <div style="width: 100%; display: flex;padding-left: 11px;">
                <div>
                  <a (click)="onMobileNavItemClick('contact')">{{'manager_kontact' | translate}}</a><br/>
                  <a target="_blank" href="legaldocs/impressum.html">{{'manager_impressum' | translate}}</a><br/>
                  <a target="_blank" href="assets/pdf/TermsOfUse.pdf">{{'manager_terms_of_use' | translate}}</a><br/>
                  <a target="_blank" href="assets/pdf/T&C.pdf">{{'manager_landing_terms_conditions' | translate}}</a>
                  <!--<a target="_blank" href="legaldocs/datenschutz.html">{{'manager_datenschutz' | translate}}</a><br/>-->
                </div>
                <div style="position: absolute;top:11px;right:-12px;">
                  <a href="https://www.facebook.com/logonsales/" target="_blank">
                    <img src="assets/icons/fb.svg" alt="" class="fb-insta mr-2 mt-n3">
                  </a>
                  <a href="https://www.instagram.com/we.are.logon/" target="_blank">
                    <img src="assets/icons/instagram.svg" alt="" class="fb-insta mr-4 mt-n3">
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center" style="margin-top: 15px;margin-bottom: 10px;">
              <div class="d-inline">
                <a>
                  <img src="assets/icons/bundes.svg" alt="" class="footer-image-mobile">
                </a>
                <a>
                  <img src="assets/icons/dsgvo.svg" alt="" class="footer-image1-mobile">
                </a>
              </div>
            </div>
            <p class="mt-2 mb-0"
               style="font-size: 0.8rem;color: #c1c1c1;margin-top: 8px !important;text-align: center;padding-bottom: 20px;">
              &copy; 2021 LogOn Tech GmbH</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- END navbar only visible on max-width:649px -->

<div class="component-container">
  <div class="nav-workspace-container">

    <!-- START workspaces list -->
    <div class="workspace-wrapper d-none d-md-flex" style="padding-bottom: 15px;" [class.workspace-search]="showWorkspacesSearch">
      <div style="position: fixed;top:0;z-index: 2;padding-right: 3px;padding-left: 3px;padding-bottom: 5px;background-color: #5223CA;">
        <button class="btn btn-outline-light workspace-search-btn" [ngStyle]="showWorkspacesSearch && {'background-color':'#f8f9fa','color':'#212529'}"
        (click)="onWorkspaceSearchClick()">
          <span *ngIf="!showWorkspacesSearch"><i class="bi bi-search mr-1"></i>{{'manager_jobs_search' | translate}}</span>
          <span *ngIf="showWorkspacesSearch">{{'manager_close' | translate}}</span>
        </button>
      </div>
      
      <!-- placholder because of fixed search button -->
      <div style="min-height: 36px"></div>
      
      <fieldset *ngFor="let enterprise of enterprises" class="text-white enterprise-workspace"
              style="position: relative;">
        <legend [ngbTooltip]="enterprise.name"
                placement="right"
                container="body"
                class="legend">{{enterprise.name.slice(0, 4) || 'N/A'}}</legend>
        <div
          *ngFor="let workspace of enterprise.workspaces | slice:0:enterprise.id == selectedEnterpriseId ? enterprise.workspaces.length : 1"
          [class.active-workspace-container-enterprises]="workspace?.id == selectedWorkspace?.id" class="mb-1 mt-1">
          <div [ngbTooltip]="!workspace.active ? 'Inactive workspace' : workspace?.name"
              placement="right"
              container="body"
              [class.inactive-workspace]="!workspace?.active" class="box"
              [class.active-workspace]="workspace?.id == selectedWorkspace?.id"
              (click)="changeWorkspace(workspace.id);"
              style="position: relative">
              <img
                *ngIf="!!workspace.image"
                [src]="workspace.image ? storageUrl + workspace.image : 'assets/icons/logo-image.svg'"
                class="logo-img-enterprise " style="background-color: rgb(233, 236, 239);" alt="workspace">
                <div [class.img-overlay]="!!workspace.image"></div>
                <i *ngIf="!workspace?.active && workspace?.id != selectedWorkspace?.id"
                  class="bi bi-exclamation-circle inactive-ws-icon workspace-name-with-img mr-1" [class.text-white]="!!workspace.image"></i>
                <span class="workspace-name-with-img" [class.text-white]="!!workspace.image">{{workspace?.name?.slice(0, 2)}}</span>
          </div>
        </div>
        <div *ngIf="enterprise.id == selectedEnterpriseId && enterprise?.owner_user_id == authService?.getUser()?.id"
            class="box create-workspace-box" [ngbTooltip]="('manager_add_recruiting_space' | translate)" placement="right" container="body"
            (click)="addWorkspace(enterprise,true);">+
        </div>
        <i class="bi bi-chevron-down expand-position pointer"
          *ngIf="enterprise?.workspaces?.length > 1 || enterprise?.owner_user_id == authService?.getUser()?.id"
          [class.rotate-enterprise-chevron]="enterprise.id == this.selectedEnterpriseId"
          (click)="toggleEnterpriseWorkspaces(enterprise.id);">
        </i>
      </fieldset>

      <div class="workspace-container">
        <div *ngFor="let userWorkspace of userWorkspaces" class="row"
            [class.active-workspace-container]="userWorkspace?.workspace?.id == selectedWorkspace?.id">
          <div [ngbTooltip]="!userWorkspace?.workspace?.active ? 'Inactive workspace' : userWorkspace?.workspace?.name"
                placement="right" container="body"
              [class.inactive-workspace]="!userWorkspace?.workspace?.active" class="box"
              [class.active-workspace]="userWorkspace?.workspace?.id == selectedWorkspace.id"
              (click)="changeWorkspace(userWorkspace.workspace.id)"
              [style.margin]="userWorkspace?.workspace?.id != selectedWorkspace.id ? '5px' : '0px'"
              style="position: relative;">
              <img
              *ngIf="!!userWorkspace.workspace.image"
              [src]="userWorkspace.workspace.image ? storageUrl + userWorkspace.workspace.image : 'assets/icons/logo-image.svg'"
              class="logo-img" style="background-color: rgb(233, 236, 239);">
              <div [class.img-overlay]="!!userWorkspace.workspace.image"></div>
              <i *ngIf="!userWorkspace?.workspace?.active && userWorkspace?.workspace?.id != selectedWorkspace.id"
                  class="bi bi-exclamation-circle inactive-ws-icon workspace-name-with-img mr-1" [class.text-white]="!!userWorkspace.workspace.image"></i>
              <span class="workspace-name-with-img" [class.text-white]="!!userWorkspace.workspace.image">{{userWorkspace?.workspace?.name?.slice(0, 2)}}</span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="box create-workspace-box add-license-button" [ngbTooltip]="('manager_add_license' | translate)" placement="right" container="body" (click)="addWorkspace('',false)">+</div>
        </div>
      </div>
    </div>
    <!-- END workspaces list -->

    <div class="sidebar active">
      <!-- START Workspaces search -->
      <div class="workspaces-search-component" [ngStyle]="showWorkspacesSearch && {'left': '0px'}">
        <div class="input-group" style="flex-wrap: nowrap;">
          <div class="input-group-prepend">
            <button class="btn bg-white btn-icon-inside-input" type="button">
              <img src="assets/icons/search.svg">
            </button>
          </div>
          <input type="text" class="form-control" style="border-top-right-radius: 4px;border-bottom-right-radius: 4px;"
                [formControl]="workspaceSearchCtrl" placeholder="RECRUITING SPACE" aria-label="" aria-describedby="basic-addon1">
          <div *ngIf="workspaceSearchCtrl?.value?.length > 0" class="pointer" style="position: absolute;top: 9px;right: 7px;font-size: 14px;z-index: 9999;" (click)="clearWorkspaceSearch()"><i class="bi bi-x-lg"></i></div>
        </div>
        <div id="num-of-recruiting-spaces">{{allRecruitingSpaces.length}} Recruiting Space<span *ngIf="allRecruitingSpaces.length != 1">s</span></div>
        <div class="recruiting-space-cards-wrapper">
          <div *ngFor="let recruitingSpace of allRecruitingSpaces" (click)="changeWorkspace(recruitingSpace.id)" class="recruiting-space-card">
            <div class="d-flex" style="column-gap: 10px;align-items: center;">
              <!-- Below is the rectangular form with the recruiting space abbreviation in it -->
              <!-- <div style="position: relative;height: 40px;width: 40px;border-radius: 4px;background-color: #ff278d;">
                <img *ngIf="!!recruitingSpace.image" [src]="recruitingSpace.image ? storageUrl + recruitingSpace.image : 'assets/icons/logo-image.svg'"
                      class="logo-img" style="background-color: rgb(233, 236, 239);">
                <div [class.img-overlay]="!!recruitingSpace.image"></div>
                <i *ngIf="!recruitingSpace?.active && recruitingSpace?.id != selectedWorkspace.id"
                    class="bi bi-exclamation-circle inactive-ws-icon workspace-name-with-img mr-1" [class.text-white]="!!recruitingSpace.image"></i>
                <span class="workspace-name-with-img" [class.text-white]="!!recruitingSpace.image" style="top: 50%;left: 50%;transform: translate(-50%, -50%);">{{recruitingSpace?.name?.slice(0, 2)}}</span>
              </div> -->
              <div style="font-size: 13px;">{{recruitingSpace.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Workspaces search -->

      <div class="logo">
        <div routerLink="/" style="display:flex;transition: all 1s ease;cursor: pointer;">
          <img src="/assets/2022_03_16_justapplai_quer_weiß_Markenkennzeichnung_SVG.svg" alt="LOGON"
               class=" d-lg-inline img-fluid " style="width: 140px;margin-left: 15px">
        </div>
      </div>
      <i class="bi bi-list" id="sidebar-menu-btn" (click)="sidebarMenuBtnToggle()"></i>
      <ul class="nav-list justappli-nav-list">
        <div
          [class.disabled]="!authService.isLoggedIn() || !selectedWorkspace?.active || !authService.getWorkspaceUser()?.terms_and_conditions">
          <li style="margin-bottom: 4px;" [class.active]="menuActive('/dashboard', true)"
              [class.selectedSidebarItem]="'dashboard' === selectedSidebarItem"
              (click)="setSelectedSidebarItem('dashboard')">
            <a [routerLink]="['/admin/workspaces',authService.getCurrentWorkspaceId(), 'dashboard']">
              <i class="bi bi-bar-chart"></i>
              <span class="links-name">{{'manager_dashboard' | translate}}</span>
            </a>
            <span class="li-tooltip">{{'manager_dashboard' | translate}}</span>
          </li>
          <li style="margin-bottom: 4px;" [class.active]="menuActive('/jobs')"
              [class.selectedSidebarItem]="'jobs' === selectedSidebarItem"
              (click)="setSelectedSidebarItem('jobs')" [hasPermission]="'JOB'">
            <a [routerLink]="['/admin/workspaces',authService.getCurrentWorkspaceId(), 'jobs']">
              <i class="bi bi-briefcase"></i>
              <span class="links-name">{{'manager_my_job_ads' | translate}}</span>
            </a>
            <span class="li-tooltip">{{'manager_my_job_ads' | translate}}</span>
          </li>

          <li style="margin-bottom: 4px;" [hasPermission]="'CANDIDATES'" [class.active]="menuActive('/candidates')"
              [class.selectedSidebarItem]="'candidates' === selectedSidebarItem"
              (click)="setSelectedSidebarItem('candidates')">
            <a [routerLink]="['/admin/workspaces',authService.getCurrentWorkspaceId(), 'candidates']"
               class="text-decoration-none">
              <i class="bi bi-people"></i>
              <span class="links-name">{{'manager_candidates' | translate}}</span>
            </a>
            <span class="li-tooltip">{{'manager_candidates' | translate}}</span>
          </li>

          <li style="margin-bottom: 4px;" [hasPermission]="'MEDIA'" [class.active]="menuActive('/media')"
              [class.selectedSidebarItem]="'media' === selectedSidebarItem"
              (click)="setSelectedSidebarItem('media')">
            <a [routerLink]="['/admin/workspaces', authService.getCurrentWorkspaceId(), 'media']">
              <i class="bi bi-card-image"></i>
              <span class="links-name">{{'manager_media' | translate}}</span>
            </a>
            <span class="li-tooltip">{{'manager_media' | translate}}</span>
          </li>

          <li style="margin-bottom: 4px;" [hasPermission]="'LICENSE'" [class.active]="menuActive('/license')"
              [class.selectedSidebarItem]="'license' === selectedSidebarItem"
              (click)="setSelectedSidebarItem('license')">
            <a [routerLink]="['/admin/workspaces',authService.getCurrentWorkspaceId(), 'license']">
              <i class="bi bi-currency-dollar"></i>
              <span class="links-name">{{'manager_license' | translate}}</span>
            </a>
            <span class="li-tooltip">{{'manager_license' | translate}}</span>
          </li>
        </div>

        <li [class.selectedSidebarItem]="'manage' === selectedSidebarItem" *ngIf="canManageWorkspace"
            [class.disabled]="!authService.isLoggedIn() || !selectedWorkspace?.active"
            [class.active]="menuActive('/manage')" (click)="setSelectedSidebarItem('manage')">
          <a [routerLink]="['/admin/workspaces', authService.getCurrentWorkspaceId(), 'manage']"
             style="font-size: 15px">
            <i class="bi bi-building"></i>
            <span class="links-name">{{'manager_workspace' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_workspace' | translate}}</span>
        </li>
        <hr class="text-white bg-white hori">


        <li style="margin-bottom: 4px;" [class.active]="menuActive('/admin/statistic')"
            [hasPermission]="'STATISTIC'"
            [class.selectedSidebarItem]="'statistic' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('statistic')">
          <a [routerLink]="['/admin/statistic']">
            <i class="bi bi-graph-up"></i>
            <span class="links-name">{{'manager_statistic' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_statistic' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/vouchers')"
            [hasPermission]="'VOUCHER'"
            [class.selectedSidebarItem]="'vouchers' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('vouchers')">
          <a [routerLink]="['/admin/vouchers']">
            <i class="bi bi-gift"></i>
            <span class="links-name">{{'manager_vouchers' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_vouchers' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/feed-config')"
            [hasPermission]="'FEED'"
            [class.selectedSidebarItem]="'feed' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('feed')">
          <a [routerLink]="['/admin/feed-config']">
            <i class="bi bi-gear-wide"></i>
            <span class="links-name">{{'manager_feed' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_feed' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/users')"
            [hasPermission]="'USER'"
            [class.selectedSidebarItem]="'users' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('users')">
          <a [routerLink]="['/admin/users']">
            <i class="bi bi-people"></i>
            <span class="links-name">{{'manager_statistic_users' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_statistic_users' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/roles')"
            [hasPermission]="'ROLE'"
            [class.selectedSidebarItem]="'roles' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('roles')">
          <a [routerLink]="['/admin/roles']">
            <i class="bi bi-person-lines-fill"></i>
            <span class="links-name">{{'manager_roles' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_roles' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/workspaces')"
            [hasPermission]="'WORKSPACE'"
            [class.selectedSidebarItem]="'workspaces' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('workspaces')">
          <a [routerLink]="['/admin/workspaces']">
            <i class="bi bi-person-bounding-box"></i>
            <span class="links-name">{{'manager_workspaces' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_workspaces' | translate}}</span>
        </li>

        <li [class.active]="menuActive('/admin/settings')" [hasPermission]="'SETTINGS'"
            [class.selectedSidebarItem]="'settings' === selectedSidebarItem"
            (click)="setSelectedSidebarItem('settings')">
          <a [routerLink]="['/admin/settings']">
            <i class="bi bi-gear"></i>
            <span class="links-name">{{'manager_settings' | translate}}</span>
          </a>
          <span class="li-tooltip">{{'manager_settings' | translate}}</span>
        </li>
      </ul>

      <div class="sidebar-footer">
        <p class="footer-links" style="margin-bottom: 0px;">
          <a (click)="commonService.openContactModal()">{{'manager_kontact' | translate}}</a><br/>
          <a target="_blank" href="legaldocs/impressum.html">{{'manager_impressum' | translate}}</a><br/>
          <!--<a target="_blank" href="legaldocs/datenschutz.html">{{'manager_datenschutz' | translate}}</a><br/>-->
          <a target="_blank" href="assets/pdf/TermsOfUse.pdf">{{'manager_terms_of_use' | translate}}</a><br/>
          <a target="_blank" href="assets/pdf/T&C.pdf">{{'manager_landing_terms_conditions' | translate}}</a>
        </p>
        <div class="d-inline">
          <a href="https://www.facebook.com/logonsales/" target="_blank">
            <img src="assets/icons/fb.svg" alt="" class="fb-insta mr-2" style="margin-top: -4px;">
          </a>
          <a href="https://www.instagram.com/we.are.logon/" target="_blank">
            <img src="assets/icons/instagram.svg" alt="" class="fb-insta mr-4" style="margin-top: -4px;">
          </a>
          <a routerLink="/">
            <img src="assets/icons/bundes.svg" alt="" class="footer-image">
          </a>
          <a routerLink="/">
            <img src="assets/icons/dsgvo.svg" alt="" class="footer-image1">
          </a>
        </div>
        <p class="mb-0" style="margin-top: 3px;font-size: 0.8rem;color: #c1c1c1;">&copy; {{year}} LogOn Tech GmbH</p>
      </div>

      <div class="profile-content">
        <div class="profile">
          <div class="profile-details">
            <img [src]="authService.getUser()?.avatar_url || 'assets/icons/profile-white.png'"
                 class="mr-2 admin-profile-image">
            <div class="name">{{authService.isLoggedIn() ? authService.getUserName() : ' '}}</div>
          </div>
          <i class="bi bi-box-arrow-left" id="log-out" (click)="logout()"></i>
        </div>
      </div>
    </div>

  </div>

  <div class="selected-content-container">
    <div class="selected-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
