import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {AbstractService} from './abstract.service';
import {tap} from 'rxjs/operators';
import {Store} from './store';
import {UserService} from './user.service';
import {ActivatedRoute} from '@angular/router';
import {PlatformLocation} from '@angular/common';


@Injectable({providedIn: 'root'})
export class AuthService extends AbstractService {
  constructor(protected http: HttpClient,
              public translate: TranslateService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private store: Store, private location: PlatformLocation) {
    super(http);
  }

  login(user): Observable<any> {
    return this.http.post(this.buildUrl('/auth/login'), {
      email: user.email,
      password: user.password
    }).pipe(
      tap((data: any) => localStorage.setItem('token', data.original.access_token))
    );
  }

  getUserDetailedWorkspace() {
    return this.store.getStore('user')?.workspaces[this.getWorkspaceIndex()];
  }


  register(payload): Observable<any> {
    return this.http.post(this.buildUrl('/auth/register'), payload);
  }

  verifyEmail(payload): Observable<any> {
    return this.http.get(this.buildUrl('/auth/complete-registration'), {
      params: {
        email: encodeURIComponent(payload.email),
        token: payload.token
      }
    });
  }

  forgetPass(user): Observable<any> {
    return this.http.post(this.buildUrl('/auth/forget'), {
      email: user.email
    });
  }

  resetPass(payload): Observable<any> {
    return this.http.post(this.buildUrl('/auth/reset-password'), payload);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('ws_index');
    this.store.setStore('user', null);
  }

  getSession() {
    let session = sessionStorage.getItem('session_id');
    if (!session) {
      session = uuid();
      sessionStorage.setItem('session_id', session);
    }
    return session;
  }

  getUserData() {
    return this.http.get(this.buildUrl('/auth/init'))
      .pipe(tap((user: any) => {
        this.store.setStore('user', user);
        this.changeLanguage((user?.language || 'de').toLowerCase(), false);
        this.setUserWorkspace();
      }));
  }

  checkResetPasswordToken(email, token) {
    return this.http.get(this.buildUrl('/auth/verify-reset-password-token'), {
      params: {
        token: token,
        email: encodeURIComponent(email)
      }
    });
  }

  getToken() {
    return localStorage.getItem('token');
  }

  hasToken() {
    return !!this.getToken();
  }

  getUser() {
    return this.store.getStore('user');
  }

  getUserId() {
    return this.store.getStore('user') ? this.store.getStore('user').id : null;
  }

  getUserName() {
    if (!this.store.getStore('user').first_name) {
      return 'Account';
    }

    if (!this.store.getStore('user').last_name) {
      return this.store.getStore('user').first_name;
    }

    return this.store.getStore('user').first_name + ' ' + this.store.getStore('user').last_name;
  }

  isLoggedIn() {
    return this.hasToken() && this.store.getStore('user');
  }

  changeLanguage(languageCode, dbChange = true) {
    this.translate.use(languageCode);
    if (dbChange && this.isLoggedIn()) {
      this.userService.updateUser(this.getUserId(), {'language': languageCode.toUpperCase()}, true).toPromise().then();
    }
  }

  hasPermission(permissionCode: string, readWrite = false) {
    const permission = this.getWorkspaceUser().permissions.find(item => item.permission_code.code == permissionCode) || this.getUser().permissions.find(item => item.permission_code.code == permissionCode);
    return permission && (!readWrite || permission.access_right == 'READ_WRITE');
  }

  getWorkspaceUser() {
    return this.store.getStore('user')?.workspaces[this.getWorkspaceIndex()];
  }

  getCurrentWorkspace() {
    return this.store.getStore('user')?.workspaces[this.getWorkspaceIndex() || 0]?.workspace;
  }

  getCurrentWorkspaceId() {
    return this.getCurrentWorkspace()?.id;
  }

  setUserWorkspace(idx?) {
    const index = idx ?? this.getWorkspaceIndex();
    localStorage.setItem('ws_index', index);


    // const urlWorkspaceId = /workspaces\/([0-9]+)\/?.*/g.exec(this.location?.pathname)?.[1] || null;
    // const workspaces = this.getUser()?.workspaces;
    //
    // if(!urlWorkspaceId || urlWorkspaceId == workspaces[index]?.workspace?.id){
    //   localStorage.setItem('ws_index',index);
    // }
    //
    // if(urlWorkspaceId !== workspaces[index]?.workspace?.id){
    //   const foundedIndex = workspaces.findIndex(ws => ws.workspace.id == urlWorkspaceId);
    //    foundedIndex > -1 && localStorage.setItem('ws_index',foundedIndex);
    // }
  }

  getWorkspaceIndex() {
    return localStorage.getItem('ws_index') || '0';
  }
}
