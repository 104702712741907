import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../services/common.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  token = localStorage.getItem('token');
  isMenuCollapsed = true;

  languages = [
    {
      id: 'en',
      name: 'EN',
      flag: ''
    },
    {
      id: 'de',
      name: 'DE',
      flag: ''
    }
  ];
  selectedLanguage;
  year: any;

  constructor(public translate: TranslateService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public commonService: CommonService,
               public authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.selectedLanguage = localStorage.getItem('language') || this.translate.getDefaultLang() || this.languages[0].id;
    this.switchLang(this.selectedLanguage);
    this.year = new Date().getFullYear();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  toRegister() {
    this.router.navigate(['/auth/register']);
  }

  toLogin() {
    this.router.navigate(['/auth/login']);
  }

  toAdmin() {
    this.router.navigate([`admin/workspaces/${this.authService.getCurrentWorkspaceId()}/dashboard`]);
  }

  createJob() {
    this.router.navigate([`admin/jobs/add/modal`]);
  }

}
