import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JobService} from '../../services/job.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AbstractComponent} from '../abstract.component';
import {CommonService} from '../../services/common.service';
import {UserWorkspaceService} from '../../services/user-workspace.service';
import {AuthService} from '../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from "../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {finalize, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-job-share',
  templateUrl: './job-share.component.html',
  styleUrls: ['./job-share.component.scss']
})

export class JobShareComponent extends AbstractComponent implements OnInit {
  @Input('job') job;

  jobShareForm: FormGroup;

  externalUsers: any[] = [];
  jobUsers: any[] = [];
  userRole: any;
  errors: any;

  errorText: boolean = false;
  errorExternalShare: any

  constructor(private activeModal: NgbActiveModal,
              private loader: NgxSpinnerService,
              public _authService: AuthService,
              private jobService: JobService,
              public modalService: NgbModal,
              private _router: Router,
              public translate: TranslateService,
              private commonService: CommonService,
              public userWorkspace: UserWorkspaceService,
              private _fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getJobUsers();
  }

  getJobUsers() {
    this.loader.show();
    this.jobService.getJobUsers(this.job.id).subscribe(users => {
      this.jobUsers = users?.workspace_users;
      const groupedExternalUsers = {};

      users?.external_users.forEach(user => {
        const then = new Date(user.created_at);
        groupedExternalUsers[user.email] = groupedExternalUsers.hasOwnProperty(user.email) ? [...groupedExternalUsers[user.email], user] : [user];

        const msBetweenDates = Math.abs(then.getTime() - new Date().getTime());
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        user['status'] = hoursBetweenDates < 24 ? 'active' : 'expired'
        return user;
      });

      this.externalUsers = []
      Object.entries(groupedExternalUsers).map(([_, value]: [key: string, value: any[]]) => this.externalUsers.push(value[value?.length - 1]));
      this.loader.hide();
    })
  }

  shareJobWithUser(inviteWithShare = false) {
    this.jobShareForm.get('role').value === 'owner' ? this.changeJobOwner(inviteWithShare) : this.addUserToJob(inviteWithShare);
  }

  addUserToJob(inviteWithShare = false) {
    const payload = {
      email: this.jobShareForm.get('email').value,
      invite_with_share: inviteWithShare,
      role: this.jobShareForm.get('role').value
    }

    this.jobService.shareJobToEmail(this.job.id, payload).subscribe(data => {
      this.errorText = false;
      if (this.jobShareForm.get('role').value === 'external') {
        const index = this.externalUsers.findIndex(user => user.email ===payload?.email);
        const newUser = {
          created_at: new Date(),
          email: this.jobShareForm.get('email').value,
          status: 'active'
        };
        index > -1 ? this.externalUsers = [...this.externalUsers.slice(0,index), newUser, ...this.externalUsers.slice(index + 1)] :  this.externalUsers.push(newUser);
        return;
      }

      this.jobUsers = [...this.jobUsers, data];
      this.resetFormToInitialState();
    }, async error => {
      this.jobError(error)
      await this.handleErrors(error);
    })
  }

  jobError(error): void {
    if (error?.status === 400 || error?.status === 409) {
      this.errorText = true;
      this.errorExternalShare = this.translate.instant('manager_error_external_share')
    }
  }

  private changeJobOwner(invite = false) {
    const payload = {
      email: this.jobShareForm.get('email').value,
      role: this.jobShareForm.get('role').value,
      invite_with_share: invite,
    }
    this.jobService.changeJobOwner(this.job.id, payload).subscribe(job => {
      this.onClose();
    }, async error => {
      await this.handleErrors(error);
    })
  }

  async confirmInvitation(error) {
    const payload = {
      user_email: this.jobShareForm.get('email').value,
      workspace_id: this._authService.getCurrentWorkspaceId(),
      role: this.jobShareForm.get('role')?.value.toUpperCase(),
      invite_with_share: true,
    }
    if (await this.commonService.confirm('manager_attention', error)) {
      this.userWorkspace.inviteToWorkspace(this._authService.getCurrentWorkspaceId(), payload).subscribe(data => {
        this.shareJobWithUser(true);
      });
    }
  }

  onClose(data?) {
    this.activeModal.close(data);
  }

  resendLink(user) {
    this.loader.show();
    this.jobService.shareJobToEmail(this.job.id, {
      email: user.email,
      role: 'external',
    }).pipe(takeUntil(this.destroyed$), finalize(() => this.loader.hide())).subscribe(() => user.created_at = Date.now())
  }

  removeExternalUserFromJob(user: any, i: number) {
    this.jobService.removeExternalUser(this.job.id, user.workspace_user_id, user.id).subscribe(res => {
      this.externalUsers = [...this.externalUsers.slice(0, i), ...this.externalUsers.slice(i + 1)]
    });
  }

  removeUserFromJob(user, i: number) {
    this.jobService.removeUserFromJob(this.job.id, user.id).subscribe(res => {
      this.jobUsers = [...this.jobUsers.slice(0, i), ...this.jobUsers.slice(i + 1)]
    });
  }

  private initializeForm(): void {
    this.jobShareForm = this._fb.group({
      email: [null, Validators.required],
      role: ['follower', Validators.required],
    });
  }

  private resetFormToInitialState() {
    this.jobShareForm.reset();
    this.jobShareForm.get('role')?.setValue('follower');
  }

  private async handleErrors(error) {
    if (error.status == 404) {
      this.errors = this.translate.instant('manager_user_doesnt_exist_in_workspace')
    }
    if (error.status == 417) {
      this.errors = this._authService.getCurrentWorkspace()?.enterprise_id == null ? this.translate.instant('manager_workspace_info_text_user', {price: environment.price}) : this.translate.instant('manager_workspace_info_text_enterprise')
      await this.confirmInvitation(this.errors);
    }
    setTimeout(() => this.errors = '', 5000);
  }

}
