// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://justappl.talentedgeai.com/api',
  // api_url: 'http://192.168.3.116/api',
  // api_url: 'https://test-api.justappl.ai/api',
  just_applai_url: 'https://app.justappl.talentedgeai.com/',
  //just_applai_url: 'http://dev-app.justappl.ai/',
  storage_url: 'https://justappl.talentedgeai.com/api/storage/',
  logout_url: 'https://justappl.talentedgeai.com/',
  price: 7 + '€',
  loom_embed_code_link_for_registration: ''
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
