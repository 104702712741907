import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-delegate-email',
  templateUrl: './delegate-email.component.html',
  styleUrls: ['./delegate-email.component.scss']
})
export class DelegateEmailComponent implements OnInit {

  @Input('job') job: any;
  delegateForm: FormGroup;
  errors = [];

  constructor(private activeModal: NgbActiveModal,
              private _fb: FormBuilder,
              public commonService: CommonService,
              private loader: NgxSpinnerService) { }

  ngOnInit(): void {
    this.delegateForm = this._fb.group({
      email:[null,Validators.required]
    })
    if (this.job.delegate_user?.email) {
      this.delegateForm.setValue({email: this.job?.delegate_user?.email})
    }
  }

  sendToEmail() {
    this.errors = [];
    if (!this.delegateForm.get('email').value) {
      return;
    }
    this.delegateForm.get('email').setErrors(null);
    this.loader.show();
    this.commonService.delegate(this.job.id,this.delegateForm.get('email').value).subscribe(data => {
      this.loader.hide();
      this.onClose();
    }, error => {
      this.loader.hide();
      if (error.status == 400) {
        for (const i in error.error.data) {
          this.errors.push(error.error.data[i])
        }
      }
      setTimeout(() => this.errors = [], 5000);
    });
  }

  onClose() {
    this.activeModal.close('JOB_DELEGATED');
  }
}
