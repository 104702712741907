import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {JobService} from '../@LogOn/services/job.service';
import {CommonService} from '../@LogOn/services/common.service';
import {LicenseService} from '../@LogOn/services/license.service';

@Injectable({
    providedIn: 'root'
  })
export class AdminResolver implements Resolve<any> {

  constructor(public jobService: JobService,
              private commonService: CommonService,
              private licenseService: LicenseService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return forkJoin({
        // REMEMBER: without login
        skills: this.jobService.getSkills(),
        subSkills: this.jobService.getSubSkills(),
        countries: this.commonService.getCountries(),
        packages: this.licenseService.getPackages()
      }
    );
  }
}
