import {Observable, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export abstract class AbstractComponent implements OnDestroy {

  _destroyed$ = new Subject();
  destroyed$: Observable<any> = this._destroyed$.asObservable();

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
