<ngx-loading-bar color="#FF2F8E" [fixed]="true"></ngx-loading-bar>
<router-outlet></router-outlet>
<ng-snotify></ng-snotify>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
</ngx-spinner>
<ng-template #contact let-modal>
  <app-contact></app-contact>
</ng-template>
