import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {map, tap} from 'rxjs/operators';
import {SnotifyService} from 'ng-snotify';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DialogComponent} from '../components/dialog/dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {Store} from './store';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends AbstractService {

  private contactModalRef: any;


  constructor(protected http: HttpClient,
              private snotify: SnotifyService,
              private store: Store,
              private translate: TranslateService,
              private modalService: NgbModal) {
    super(http);
  }

  getCountries() {
    if (this.store.has('countries')) {
      return of(this.store.getStore('countries'));
    }
    return this.get('/countries').pipe(tap(data => this.store.setStore('countries', data)));
  }

  zipCodeSearch(countryId, word, zipCode) {
    if (!countryId)
      countryId=1
    return this.get('/zip-codes', {params: {country_id: countryId, word: word, zip_code: zipCode}})
      .pipe(map((items: any[]) => {
        return items.map(item => {
          return {...item, display_name: item.zip_code + ' ' + item.city};
        });
      }));
  }

  public confirm(title: string, message: string) {
    return this.dialog(title, message);
  }

  public info(title: string, message: string) {
    return this.dialog(title, message, 'info');
  }

  public dialog(
    title: string,
    message: string,
    type: 'confirm' | 'info' = 'confirm',
    btnOkText: string = null,
    btnYesText: string = null,
    btnNoText: string = null,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(DialogComponent, {size: dialogSize, centered: true, modalDialogClass: 'logon-dialog'});
    modalRef.componentInstance.title = this.translate.instant(title);
    modalRef.componentInstance.message = this.translate.instant(message);
    modalRef.componentInstance.btnOkText = this.translate.instant(btnOkText || 'manager_okey');
    modalRef.componentInstance.btnYesText = this.translate.instant(btnYesText || 'manager_yes');
    modalRef.componentInstance.btnNoText = this.translate.instant(btnNoText || 'manager_no');
    modalRef.componentInstance.type = type;

    return modalRef.result;
  }

  upload(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.post(`/upload`, formData);
  }

  contact(data: any) {
    return this.post('/contact', data);
  }

  delegate(jobId, email: any) {
    return this.post(`/jobs/${jobId}/delegate`, {email: email});
  }

  setContactModalRef(modal) {
    this.contactModalRef = modal;
  }

  openContactModal() {
    this.modalService.open(this.contactModalRef, {size: 'lg'});
  }

  getVideoLink(video_link_id: string, video_link_type: string): any {
    if(video_link_type == 'youtube'){
      return `https://www.youtube.com/watch?v=${video_link_id}`;
    }
    if(video_link_type == 'vimeo'){
      return `https://vimeo.com/${video_link_id}`;
    }
    return '';
  }

  getPublicVoucher(voucherName) {
    return this.get(`/public-vouchers/${voucherName}`);
  }

  getAllVouchers() {
    return this.get('/vouchers');
  }

  insertVoucher(payload) {
    return this.post(`/vouchers`, payload)
  }

  updateVoucher(voucherId, payload) {
    return this.put(`/vouchers/${voucherId}`, payload)
  }

  sendPublicVoucher(userId, voucherCode) {
    return this.post(`/users/${userId}/vouchers/${voucherCode}`, null);
  }

  getVoucher(id){
    return this.get(`/vouchers/${id}`);
  }
}
