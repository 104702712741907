<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100 text-uppercase">
    <h5>{{'manager_permissions' | translate}}</h5>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body p-3">
  <div class="row pl-3">
    <div class="col-2 text-muted">{{'ID' | translate}}</div>
    <div class="col-5 text-muted">{{'manager_name' | translate}}</div>
    <div class="col-5 text-muted">{{'manager_workspace_code' | translate}}</div>
  </div>
  <div class="row pl-3 py-2">
    <div class="col-2 text-muted font-weight-light">{{userWorkspace.workspace?.id || '-'}}</div>
    <div class="col-5 text-muted font-weight-light">{{userWorkspace.workspace.name || '-'}}</div>
    <div class="col-5 text-muted font-weight-light">{{userWorkspace.workspace.code || '-'}}</div>
  </div>
  <hr/>
  <div class="pl-3" *ngFor="let permission of allPermissions;">
    <div class="row" style="margin-bottom: 10px;" *ngIf="permission.access_level == 'WORKSPACE'">
      <div class="col-12 col-lg-4" style="padding-left: 0">
        <label class="form-check-label text-muted" style="margin-bottom: 4px;">{{'manager_user_permission_modal_' + permission.code | lowercase | translate}}</label>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-4 form-check">
            <input class="form-check-input" type="radio" id="3{{permission.id}}" name="right{{permission.id}}"
                   [checked]="permission?.access_right == 'NONE'" (change)="accessRightSelected($event, permission.id, 'NONE')">
            <label class="form-check-label text-muted font-weight-light" for="3{{permission.id}}">{{'manager_permission_none' | translate}}</label>
          </div>
          <div class="col-4 form-check">
            <input class="form-check-input" type="radio" id="1{{permission.id}}" name="right{{permission.id}}"
                   [checked]="permission?.access_right == 'READ'" (change)="accessRightSelected($event, permission.id, 'READ')">
            <label class="form-check-label text-muted font-weight-light" for="1{{permission.id}}">{{'manager_permission_read' | translate}}</label>
          </div>
          <div class="col-4 form-check">
            <input class="form-check-input" type="radio" id="2{{permission.id}}" name="right{{permission.id}}"
                   [checked]="permission?.access_right == 'READ_WRITE'" (change)="accessRightSelected($event, permission.id, 'READ_WRITE')">
            <label class="form-check-label text-muted font-weight-light" for="2{{permission.id}}">{{'manager_permission_read_and_write' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
