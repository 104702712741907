<div *ngIf="loomEmbedCodeLink" class="d-flex mb-4 mt-3 loom-container" [class.video-card]="displayAsCard" [ngStyle]="(displayAsCard && !showThumbnail) && {'padding': '0.8rem'}" (click)="openLoomVideoModal(loomVideoModal, displayAsCard)">
    <div *ngIf="showThumbnail" class="loom-video-section" (click)="openLoomVideoModal(loomVideoModal, true)">
      <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe [src]="sanitizedLoomUrl" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;pointer-events: none;"></iframe></div>
    </div>
    <div class="d-flex" style="flex-direction: column;justify-content: center;">
      <div class="text-muted" style="line-height: 1.3;">{{cardTitle | translate}}</div>
      <span id="watch-video" (click)="openLoomVideoModal(loomVideoModal, true)">{{'manager_loom_watch_video' | translate}}</span>
    </div>
</div>

<ng-template #loomVideoModal let-modal>
  <div class="modal-header text-white text-center">
    <div class="modal-title modal-title w-100 text-uppercase">
      <h5 style="margin-bottom: 0px !important;line-height: unset;">{{cardTitle | translate}}</h5>
    </div>
    <a class="close-auth pointer" aria-label="Close" (click)="loomModal.close()">
      <img src="assets/icons/close-white.svg">
    </a>
  </div>
  <div class="modal-body p-3 text-center text-muted" style="font-size: 17px;position: relative;">
    <div id="video-is-loading">{{'manager_loom_video_is_loading' | translate}}</div>
    <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe [src]="sanitizedLoomUrl" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
  </div>
</ng-template>
