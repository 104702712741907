import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {AbstractComponent} from '../abstract.component';
import {ActivatedRoute} from '@angular/router';
import {UserWorkspaceService} from '../../services/user-workspace.service';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-user-workspaces',
  templateUrl: './user-workspaces.component.html',
  styleUrls: ['./user-workspaces.component.scss']
})
export class UserWorkspacesComponent extends AbstractComponent implements OnInit {

  @Input('userWorkspace') userWorkspace;
  @Input('userId') userId;

  allPermissions;
  workspacePermissions: any[] = [];

  constructor(private activeModal: NgbActiveModal,
              private userService: UserService,
              private userWorkspaceService: UserWorkspaceService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getPermissionCodes();
  }

  getPermissionCodes() {
    this.userService.getPermissionCodes().subscribe(allPermissions => {
      this.allPermissions = allPermissions;
      this.allPermissions.forEach(permission => {
        permission['access_right'] = 'NONE';
        this.userWorkspace.permissions.forEach(workspacePermission => {
          if (workspacePermission.permission_code_id == permission.id) {
            permission['access_right'] = workspacePermission.access_right;
            permission['manual'] = workspacePermission.manual;
          }
        })
      })
    })
  }

  accessRightSelected(event, permissionCode, userRight) {
    const payload = {
      permission_code_id: permissionCode,
      access_right: userRight,
      workspace_user_id: this.userWorkspace.id
    }

    if (userRight == 'NONE') {
      this.userService.deleteUserPermission(this.userId, permissionCode, this.userWorkspace.id).subscribe((res: any) => {
        const index = this.userWorkspace.permissions.findIndex(x => x.permission_code_id == permissionCode)
        this.userWorkspace.permissions[index].access_right = userRight
      })
    } else {
      this.userService.addUserPermission(this.userWorkspace.user_id, payload).subscribe((res: any) => {
        let index = this.userWorkspace.permissions.findIndex(id => id.permission_code_id == res.permission_code_id)
        if (index == -1) {
          return
        }
        this.userWorkspace.permissions[index].access_right = res.access_right
      })
    }
  }

  onClose() {
    this.activeModal.close('WORKSPACE_PERMISSIONS');
  }
}
