import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;

  constructor(public modalService: NgbModal,
              private formBuilder: FormBuilder,
              public commonService: CommonService,
              private loader: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      full_name: [null],
      email: [null],
      company_name: [null],
      phone: [null],
      subject: [null],
      message: [null]
    });
  }

  confirm() {
    //this.contactForm.markAllAsTouched();
    this.contactForm.get('full_name').setErrors(null);
    this.contactForm.get('email').setErrors(null);
    this.contactForm.get('company_name').setErrors(null);
    this.contactForm.get('phone').setErrors(null);
    this.contactForm.get('subject').setErrors(null);
    this.contactForm.get('message').setErrors(null);

    if (!this.contactForm.get('full_name').value && !this.contactForm.get('company_name').value
      && !this.contactForm.get('subject').value && !this.contactForm.get('message').value) {
      this.contactForm.get('full_name').setErrors({'incorrect': true});
      this.contactForm.get('email').setErrors({'incorrect': true});
      this.contactForm.get('subject').setErrors({'incorrect': true});
      this.contactForm.get('message').setErrors({'incorrect': true});
    }

    if (this.contactForm.invalid) {
      return;
    }
    this.loader.show();
    this.commonService.contact(this.contactForm.getRawValue()).subscribe(data => {
      this.loader.hide();
      this.onClose();
    }, error => this.loader.hide());
  }

  onClose() {
    this.modalService.dismissAll();
  }
}
