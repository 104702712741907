import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable, of} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {Store} from './store';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class JobService extends AbstractService {

  constructor(protected http: HttpClient,
              private store: Store,
              private authService: AuthService) {
    super(http);
  }

  getSkills() {
    if (this.store.has('skills')) {
      return of(this.store.getStore('skills'));
    }
    return this.get('/skills').pipe(tap(data => this.store.setStore('skills', data)));
  }

  getSubSkills() {
    if (this.store.has('sub_skills')) {
      return of(this.store.getStore('sub_skills'));
    }
    return this.get('/sub-skills').pipe(tap(data => this.store.setStore('sub_skills', data)));
  }

  getSkillJobTitles(skillId) {
    return this.get(`/skills/${skillId}/job-titles`);
  }

  addSkill(name) {
    return this.post(`/skills`, {name: name});
  }

  addJobTitle(name, skillId, kritKatId) {
    return this.post(`/job-titles`, {name: name, skill_id: skillId, krit_kat_id: kritKatId});
  }

  jobTitleSearch(word, simple) {
    return this.get('/job-titles/search', {params: {word, simple}});
  }

  profile(file, relativePath) {
    const formData = new FormData();
    formData.append('file', file, relativePath);
    return this.post('/profiles/details', formData, this.silentOption());
  }

  validateJob(data) {
    return this.post(`/jobs/validation`, data);
  }

  addOrUpdateJob(data, id, options = {}) {
    return id ? this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${id}`, data, options) : this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs`, data, options);
  }

  saveJobToUser(userId) {
    let job: any = JSON.parse(localStorage.getItem('job'));
    if (!job) {
      return of(null);
    }
    return this.addOrUpdateJob({...job, user_id: userId}, null, this.silentOption())
      .pipe(finalize(() => {
        localStorage.removeItem('job');
        localStorage.removeItem('new_job_method');
      }));
  }

  getJobPublishFlag() {
    let job: any = JSON.parse(localStorage.getItem('job'));
    return job ? job.publish_job : false;
  }

  getAllJobs(): Observable<any> {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs`);
  }

  getAllPendingJobs(): Observable<any> {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/pending`);
  }

  getJob(id, basicDataOnly = false): Observable<any> {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${id}`, {params: {basic_data_only: basicDataOnly}});
  }

  generateChat(adId, skillId, kritKatId, title, zipCode, city, countryCode, simple) {
    return this.get(`/jobs/initialise`, {
      params: {
        title,
        city,
        simple,
        ad_id: adId,
        skill_id: skillId,
        krit_kat_id: kritKatId,
        zip_code: zipCode,
        country_code: countryCode,
      }
    });
  }

  changeJobStatus(job: any, status: any) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${job.id}/status/${status}`, {});
  }

  deleteJob(job: any) {
    return this.delete(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${job.id}`);
  }

  duplicateJob(job: any) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${job.id}/duplicate`, {});
  }

  updatePreferences(job, body) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${job.id}/preferences`, body);
  }

  generateLanguage(core_json: any, language: any) {
    return this.post('/jobs/generate-language', {
      core_json: core_json,
      language: language
    });
  }

  getAnalytics(id: any) {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${id}/analytics`);
  }

  saveAfterPublish(job: any) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${job.id}/after-publish`, job);
  }

  getJobUsers(jobId) {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/users`);
  }

  shareJobToEmail(jobId, payload) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/invite`, payload);
  }

  changeJobOwner(jobId, payload) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/change-owner`, payload);
  }

  removeUserFromJob(jobId, userId) {
    return this.delete(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/user/${userId}`);
  }

  removeExternalUser(jobId, userId, jobCandidateId) {
    return this.delete(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/user/${userId}/external/${jobCandidateId}`);
  }

  userInvitationApproval(jobId) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/invite/approve`, {})
  }

  declineJob(jobId) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/invite/decline`, {})
  }
}
