import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {CommonService} from './@LogOn/services/common.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {PlatformLocation} from '@angular/common';
import {AuthService} from './@LogOn/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild('contact') contact;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private ccService: NgcCookieConsentService,
              private commonService: CommonService,
              private translateService: TranslateService,
              private titleService: Title,
  ) {


  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((ttl: string) => {
      if (ttl) {
        ttl += ' - JustApply';
      }
      this.titleService.setTitle(ttl);
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cookiesLang();
    });
    this.cookiesLang();

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        this.ccService.getConfig();
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        this.ccService.close(false);
      });

    this.initializingSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        this.ccService.getConfig().autoOpen = false;
      });

    //   this.initializingSubscription = this.ccService.initializing$.subscribe(
    //     (event: NgcInitializingEvent) => {
    //       console.log(`initializing: ${JSON.stringify(event)}`);
    //     });
    //
    //   this.initializedSubscription = this.ccService.initialized$.subscribe(
    //     () => {
    //       console.log(`initialized: ${JSON.stringify(event)}`);
    //     });
    //
    // this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
    //   (event: NgcInitializationErrorEvent) => {
    //     console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
    //   });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'allow' || event.status === 'deny') {
          this.ccService.close(false);
        }
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        this.ccService.close(false);
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        this.ccService.close(false);
      });

    // if(this.ccService && this.ccService.hasAnswered()) {
    //   this.ccService.destroy();
    // }
    // if(this.ccService.getStatus() === 'allow') {
    //   this.ccService.destroy();
    // }
  }

  cookiesLang() {
    this.translateService
      .get(['manager_cookie_header', 'manager_cookie_message', 'manager_cookie_dismiss', 'manager_cookie_allow', 'manager_cookie_deny', 'manager_cookie_link', 'manager_cookie_policy'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['manager_cookie_header'];
        this.ccService.getConfig().content.message = data['manager_cookie_message'];
        this.ccService.getConfig().content.dismiss = data['manager_cookie_dismiss'];
        this.ccService.getConfig().content.allow = data['manager_cookie_allow'];
        this.ccService.getConfig().content.deny = data['manager_cookie_deny'];
        this.ccService.getConfig().content.link = data['manager_cookie_link'];
        this.ccService.getConfig().content.policy = data['manager_cookie_policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.commonService.setContactModalRef(this.contact);
  }
}
