import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../services/common.service';
import {Store} from '../../services/store';
import {AuthService} from '../../services/auth.service';
import {AddWorkspaceComponent} from '../../components/add-workspace/add-workspace.component';
import {SYSTEM_LANGUAGES} from '../../constants/CommonConstants';
import {environment} from '../../../../environments/environment';
import { FormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
  @Input('longContent') content;
  storageUrl = environment.storage_url;
  selectedSidebarItem: string;
  selectedEnterpriseId = null;

  languages = SYSTEM_LANGUAGES;
  userWorkspaces: any[];
  selectedWorkspace: any = null;
  year: number = new Date().getFullYear();

  enterprises: any[] = [];
  canManageWorkspace: boolean = false;
  canInviteToWorkspace: boolean = false;

  showWorkspacesSearch: boolean = false;
  workspaceSearchCtrl = new FormControl();
  allRecruitingSpaces: any[] = [];
  allRecruitingSpacesCopy: any;

  constructor(public translate: TranslateService,
              public modalService: NgbModal,
              public router: Router,
              public commonService: CommonService,
              public authService: AuthService,
              private _store: Store,
              private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.getUserData();
    this.getSortedEnterpriseWorkspaces();
    if (this.selectedWorkspace.enterprise_id) {
      this.selectedEnterpriseId = this.selectedWorkspace.enterprise_id;
    }
    this.selectedSidebarItem = this.router.url.split('/')[this.router.url.split('/').length - 1];
    
    this.enterprises.forEach(enterprise => {
      let ws = null;
      for (let i = 0; i < enterprise?.workspaces?.length; i++) {
        const obj = enterprise?.workspaces[i];
        this.allRecruitingSpaces.push(obj)
        if (obj.id === this.selectedWorkspace?.id) {
          ws = _.cloneDeep(obj);
          enterprise?.workspaces.splice(i, 1); // remove the object at index i
          i--; // decrement i to account for the removed item
        }
      }
      if (ws) {
        enterprise?.workspaces.unshift(ws); // add the selected workspace to the beginning of the array
      }
    });

    this.userWorkspaces.forEach(userWorkspace => this.allRecruitingSpaces.push(userWorkspace?.workspace));
    this.allRecruitingSpacesCopy = _.cloneDeep(this.allRecruitingSpaces);
    this.workspaceSearchCtrl.valueChanges.pipe(debounceTime(100)).subscribe(searchTerm => this.filterAllRecruitingSpaces(searchTerm));
  }

  clearWorkspaceSearch() {
    this.workspaceSearchCtrl.reset();
    this.allRecruitingSpaces = _.cloneDeep(this.allRecruitingSpacesCopy);
  }

  filterAllRecruitingSpaces(searchTerm) {
    if (searchTerm != null) {
      this.allRecruitingSpaces = this.allRecruitingSpacesCopy.filter(recruitingSpace => {
        return recruitingSpace.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
  }

  onHamburgerClick() {
    const menuBtn = <HTMLElement>document.querySelector('.hamburger');
    const mobileMenu = <HTMLElement>document.querySelector('.mobile-nav');

    menuBtn.classList.toggle('is-active');
    mobileMenu.classList.toggle('is-active');
  }

  onMobileNavItemClick(path): void {
    const menuBtn = <HTMLElement>document.querySelector('.hamburger');
    const mobileMenu = <HTMLElement>document.querySelector('.mobile-nav');
    menuBtn.classList.toggle('is-active');
    mobileMenu.classList.toggle('is-active');
    path === 'contact' ? this.commonService.openContactModal() : this.router.navigate([path], {relativeTo: this.route});
  }

  setSelectedSidebarItem(itemClass): void {
    this.selectedSidebarItem = itemClass;
  }

  onWorkspaceSearchClick() {
    this.showWorkspacesSearch = !this.showWorkspacesSearch;
    const sidebar = <HTMLElement>document.querySelector('.sidebar');
    const sidebarFooter = <HTMLElement>document.querySelector('.sidebar-footer');
    if (window.innerWidth > 1199) {
      if (!sidebar.classList.contains('active')) {
        sidebar.classList.add('active');
        sidebarFooter.classList.remove('active');
      }
    } else {
      if (sidebar.classList.contains('active')) {
        sidebar.classList.remove('active');
        sidebarFooter.classList.add('active');
      }
    }
  }

  sidebarMenuBtnToggle(): void {
    const sidebar = <HTMLElement>document.querySelector('.sidebar');
    const sidebarFooter = <HTMLElement>document.querySelector('.sidebar-footer');
    sidebar.classList.toggle('active');
    sidebarFooter.classList.toggle('active');
  }

  menuActive(route, fixed = false) {
    const reg = new RegExp('^' + route + (fixed ? '$' : ''));
    return !!reg.exec(this.router.url);
  }

  logout() {
    this.authService.logout();
    environment.production ? window.location.href = environment.logout_url : this.router.navigate(['/']);
  }


  addWorkspace(enterprise, enterpriseWorkspace) {
    const modalRef = this.modalService.open(AddWorkspaceComponent, {});
    if (enterpriseWorkspace) {
      modalRef.componentInstance.enterprise_id = enterprise.id;
    }

    modalRef.result.then(result => {
      if (result.workspace.enterprise_id != null) {
        if(this.enterprises.find(x => x.id == result.workspace.enterprise_id)) {
          this.enterprises.find(x => x.id == result.workspace.enterprise_id).workspaces.push(result.workspace);
        }
        else {
          this.enterprises.push(enterprise = result.workspace.enterprise);
          enterprise.workspaces = [];
          enterprise.workspaces.push(result.workspace);
        }
      }
      if (result) {
        this._store.setStore('user', {
          ...this.authService?.getUser(),
          workspaces: [...this.authService?.getUser().workspaces, result]
        });
        this.getUserData();
        this.changeWorkspace(result.workspace_id);
        this.router.navigate([`/admin/workspaces/${result.workspace_id}/license/upgrade`]);
      }
    });
  }

  getSortedEnterpriseWorkspaces() {
    for (const workspaceUser of this.authService?.getUser()?.workspaces) {
      if (!workspaceUser.workspace.enterprise_id) {
        continue;
      }
      let enterprise = this.enterprises.find((ent) => ent.id == workspaceUser.workspace.enterprise_id);
      if (!enterprise) {
        this.enterprises.push(enterprise = workspaceUser.workspace.enterprise);
        enterprise.workspaces = [];
      }
      enterprise.workspaces.push(workspaceUser.workspace);
    }
  }

  getUserData() {
    this.userWorkspaces = this.authService?.getUser()?.workspaces.filter(x => x.workspace?.enterprise_id == null);
    this.selectedWorkspace = this.authService.getCurrentWorkspace();
    this.handlePrivileges();
  }

  private handlePrivileges() {
    this.canInviteToWorkspace = this.canManageWorkspace = this.selectedWorkspace?.owner_user_id == this.authService.getUserId();
    if (this.canManageWorkspace) {
      return;
    }
    this.canInviteToWorkspace = this.canManageWorkspace = this.authService.hasPermission('WORKSPACE_MANAGE', true);
  }

  changeWorkspace(workspaceId) {
    const ws = this.authService?.getUser()?.workspaces.find(x => x?.workspace_id == workspaceId);
    this.selectedWorkspace = ws.workspace;

    const index = this.authService.getUser().workspaces.findIndex(wrs => wrs.id == ws.id);
    this.authService.setUserWorkspace(index);
    this.router.navigateByUrl('/redirect', {skipLocationChange: true}).then(() => this.router.navigate([`/admin/workspaces/${ws.workspace_id}/${ws.workspace.active ? 'dashboard' : 'license/upgrade'}`]));
  }

  toggleEnterpriseWorkspaces(enterpriseId) {
    this.selectedEnterpriseId = enterpriseId == this.selectedEnterpriseId ? null : enterpriseId;
  }
}
