import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../services/auth.service';
import {AbstractComponent} from '../abstract.component';
import {UserWorkspaceService} from '../../services/user-workspace.service';
import {finalize} from 'rxjs/operators';
import {Store} from "../../services/store";

@Component({
  selector: 'app-add-workspace',
  templateUrl: './add-workspace.component.html',
  styleUrls: ['./add-workspace.component.scss']
})
export class AddWorkspaceComponent extends AbstractComponent implements OnInit {
  workspaceForm: FormGroup;
  errorObj: any;
  enterprises: any;
  @Input() enterprise_id

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              public workspaceService: UserWorkspaceService,
              private authService: AuthService,
              public _store: Store,
              private loader: NgxSpinnerService) {
    super();
  }

  ngOnInit(): void {
    this.workspaceForm = this.formBuilder.group({
      name: [null],
      code: [null],
      active: true,
      enterprise_id: this.enterprise_id || null
    });
  }


  confirm() {
    this.handelAndCheckFormValidity();
    this.loader.show();

    this.workspaceService.addUserWorkspace(this.authService.getUserId(), this.workspaceForm.getRawValue())
      .pipe(finalize(() => this.loader.hide())).subscribe((data) => this.activeModal.close(data), error => {
      this.jobError(error)
    });
  }


  private handelAndCheckFormValidity(): void {
    this.workspaceForm.get('name').setErrors(null);

    if (!this.workspaceForm.get('name').value) {
      this.workspaceForm.get('name').setErrors({'incorrect': true});
    }

    if (this.workspaceForm.invalid) {
      return;
    }

  }

  private jobError(error): void {
    if (error?.status === 400 || error?.status == 409) {
      this.errorObj = error.error?.message
    }
  }

  onClose() {
    this.activeModal.close();
  }
}
