import {AuthLayoutComponent} from './@LogOn/layouts/auth-layout/auth-layout.component';
import {AdminLayoutComponent} from './@LogOn/layouts/admin-layout/admin-layout.component';
import {MainLayoutComponent} from './@LogOn/layouts/main-layout/main-layout.component';
import {UserResolver} from './@LogOn/services/user.resolver';
import {Routes} from '@angular/router';
import {AdminResolver} from './admin/admin-resolver.service';
import {NotFoundComponent} from './@LogOn/components/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    resolve: [UserResolver],
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: {
      title: 'Home'
    }
  },

  {
    path: 'admin',
    component: AdminLayoutComponent,
    resolve: {user: UserResolver, admin: AdminResolver},
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    data: {
      title: 'Admin'
    }
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),

    data: {
      title: 'Auth'
    }
  },

  {
    path: 'redirect',
    component: AdminLayoutComponent
  },
  {
    path: '**',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent
      }
    ]
  }
];
