export class CommonConstants {

  public static colorScheme = {
    domain: ['#5223CA', '#FF2F8E']
  };

  public static matchLevelColors = {
    domain: ['#9b9b9b', '#28A745', '#DC3545', '#ffc107']
  };

  public static allColors = {
    domain: ['#5223CAFF', '#FF2F8EFF', '#28A745FF', '#27E3BF', '#DC3545FF', '#808080']
  };

  public static educationKeys = {
    0: 'general_n/a',
    1: 'general_school',
    2: 'general_apprenticeship',
    3: 'general_university_degree',
    4: 'general_doctor',
  }

  public static careerKeys = {
    0: 'general_n/a',
    1: 'general_beginner',
    2: 'general_experienced',
    3: 'general_expert',
    4: 'general_senior',
  }

}

export const SYSTEM_LANGUAGES = [
  {
    id: 'en',
    name: 'EN',
    flag: ''
  },
  {
    id: 'de',
    name: 'DE',
    flag: ''
  },
  {
    id: 'de-informal',
    name: 'DE-INFORMAL',
    flag: ''
  },
  {
    id: 'cs',
    name: 'CS',
    flag: ''
  },
  {
    id: 'pl',
    name: 'PL',
    flag: ''
  },
  {
    id: 'uk',
    name: 'UK',
    flag: ''
  }
];

export const TRACKING_MEDIA_FIELDS = [
  {
    key: 'facebook',
    title: 'Facebook',
    placeholder: 'Facebook ID',
    selected: false,
    value: null
  },
  {
    key: 'instagram',
    title: 'Instagram',
    placeholder: 'Instagram ID',
    selected: false,
    value: null
  },
  {
    key: 'google_adverts',
    title: 'Google Adverts',
    placeholder: 'Google Adverts ID',
    selected: false,
    value: null
  },
  {
    key: 'google_analytics',
    title: 'Google Analytics',
    placeholder: 'Google Analytics ID',
    selected: false,
    value: null
  },
];



