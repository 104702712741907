import {catchError, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user.service';
import {Store} from './store';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {

  constructor(private authService: AuthService,
              private router: Router,
              private userService: UserService,
              private translate: TranslateService,
              private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.hasToken() && !this.store.getStore('user')) {
       return this.authService.getUserData().pipe(
        tap((data: any) => {
          if(data.user?.package_name){
            this.router.navigate(['/auth/choose-package'])
            return;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
    return true;
  }
}
