<nav class="navbar navbar-expand-md fixed-top bg-primary py-lg-4 text-center round-bottom">
  <div class="container-xl mb-n2 mb-md-0">
    <a class="text-left sm-logo" style="width: 200px; margin-top: -4px;" routerLink="/">
      <img style="margin-left: 2px" src="assets/2022_03_16_justapplai_quer_weiß_Markenkennzeichnung_SVG.svg" height="30">
    </a>
    <button class="navbar-toggler text-white" (click)="isMenuCollapsed = !isMenuCollapsed" type="button"
            data-bs-toggle="collapse" data-bs-target="#bdNavbar" aria-controls="bdNavbar" aria-expanded="false"
            aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
      </svg>
    </button>
    <div class="row collapse navbar-collapse text-center" id="navbarTogglerDemo02" [ngbCollapse]="isMenuCollapsed">
      <ul id="pricing-fragen" class="col-md-6 col-lg-6 navbar-nav">
        <li class="nav-item my-1 mt-md-0 nav-item-pricing d-none">
          <a class="btn btn-lg btn-primary" href="https://www.justappl.ai/preise/">
            <img src="assets/icons/preise-icon.svg">{{'manager_pricing' | translate}}
          </a>
        </li>
        <li class="nav-item my-1 mt-md-0 nav-item-fragen d-none">
        <li class="nav-item my-1 mt-md-0 nav-item-pricing d-none">
          <a class="btn btn-lg btn-primary" href="https://www.justappl.ai/faq/">
            <img src="assets/icons/fragen-icon.svg">{{'manager_fragen' | translate}}
          </a>
        </li>
      </ul>
      <ul class="col-md-6 col-lg-5 navbar-nav pl-1 pr-0 pr-xl-3 offset-lg-1 offset-xl-1 justify-content-end">
        <li class="nav-item my-auto" *ngIf="authService.isLoggedIn()">
          <button class="btn text-white font-weight-bolder text-uppercase" style="white-space: nowrap;"
                  (click)="toAdmin()">{{'manager_admin_panel' | translate}}</button>
        </li>

        <li class="nav-item my-auto" *ngIf="!authService.isLoggedIn()">
          <button class="btn text-white text-uppercase font-weight-bolder"
                  (click)="toLogin()">{{'manager_login' | translate}}</button>
        </li>
        <li class="nav-item my-auto" *ngIf="!authService.isLoggedIn()">
          <button class="btn text-white text-uppercase font-weight-bolder"
                  (click)="toRegister()">{{'manager_register' | translate}}</button>
        </li>
        <li class="nav-item my-2 my-auto">
          <button type="button" class="btn btn-secondary px-4 py-2 font-weight-bolder" id="jetzt-starten"
                  (click)="createJob()" [hasPermission]="'JOB'"
                  rw>{{'manager_preview_test' | translate}}</button>
        </li>

        <li class="nav-item ml-md-2 my-auto li-language">
          <select class="form-control form-control-outline-white font-weight-bold px-1 my-2 my-lg-0 mx-auto"
                  style="width: 53px" [(ngModel)]="selectedLanguage" (change)="switchLang(selectedLanguage)">
            <option *ngFor="let language of languages" [value]="language.id">{{language.name}}</option>
          </select>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<footer class="bg-primary text-white round-top pt-3">
  <div class="container">
    <div class="row mt-1 mt-lg-5">
      <div class="col-12 col-md-6 col-lg-6 text-center text-md-left">
        <a class="d-block" routerLink="/">
          <img class="mb-2" src="assets/2022_03_16_justapplai_quer_weiß_Markenkennzeichnung_SVG.svg" height="45">
        </a>
        <div class="my-4">
          <a href="https://www.facebook.com/logonsales/" target="_blank">
            <img src="assets/icons/fb-blue.svg" alt="" class="fb-insta mr-3">
          </a>
          <a href="https://www.instagram.com/we.are.logon/" target="_blank">
            <img src="assets/icons/insta-blue.svg" alt="" class="fb-insta">
          </a>
        </div>
        <p class="mt-5 pt-4 text-light res-footer-p" style="font-size: 0.8rem;opacity: .8">&copy; {{year}} LogOn Tech
          GmbH</p>
      </div>

      <div class="col-12 col-md-3 offset-md-3 offset-lg-0 text-center text-md-left col-lg-3 footer-link">
        <h6 class="pt-md-3 pb-1"><a class="text-white text-decoration-none"
                                    (click)="commonService.openContactModal()">{{'manager_kontact' | translate}}</a>
        </h6>
        <h6 class="pb-1"><a class="text-white text-decoration-none" target="_blank"
                            href="legaldocs/impressum.html">{{'manager_impressum' | translate}}</a></h6>
        <h6 class="pb-1"><a class="text-white text-decoration-none" target="_blank"
                            href="legaldocs/datenschutz.html">{{'manager_datenschutz' | translate}}</a></h6>
        <h6 class="pb-1"><a class="text-white text-decoration-none" target="_blank"
                            href="assets/pdf/TermsOfUse.pdf">{{'manager_terms_of_use' | translate}}</a></h6>
        <h6 class="pb-3"><a class="text-white text-decoration-none" target="_blank"
                            href="assets/pdf/T&C.pdf">{{'manager_landing_terms_conditions' | translate}}</a></h6>
      </div>
      <div class="col-12 col-md-12 col-lg-3 text-center text-lg-right mb-5 md-icons">
        <a class="mr-4" routerLink="/">
          <img src="assets/icons/bundes.svg" alt="" class="footer-image">
        </a>
        <a routerLink="/">
          <img src="assets/icons/dsgvo.svg" alt="" class="footer-image1 mt-n4">
        </a>
      </div>
    </div>
  </div>
</footer>
