import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserWorkspaceService extends AbstractService {

  constructor(protected http: HttpClient,
              public authService: AuthService,
              public router: Router,
              public activatedRoute: ActivatedRoute,
              public userService: UserService) {
    super(http);
  }

  getUserWorkspace(workspaceId) {
    return this.get(`/workspaces/${workspaceId}/users`);
  }

  addUserWorkspace(userId, payload) {
    return this.post(`/users/${userId}/workspaces`, payload);
  }

  inviteToWorkspace(workspaceId, payload) {
    return this.post(`/workspaces/${workspaceId}/invite`, payload)
  }

  deleteUserPermission(userId, workspaceId) {
    return this.delete(`/users/${userId}/workspaces/${workspaceId}`);
  }

  getCurrentWorkspace(workspaceId) {
    return this.get(`/workspaces/${workspaceId}/users`);
  }

  getWorkspacePermissions() {
    return this.get(`/workspace-permissions`);
  }

  changeWorkspaceUserPermission(currentWorkspace, userId, permissionCodeId, accessRight) {
    return this.put(`/workspaces/${currentWorkspace}/users/${userId}/permissions/${permissionCodeId}`, {access_right: accessRight});
  }

  deleteWorkspaceUser(currentWorkspace, userId) {
    return this.delete(`/workspaces/${currentWorkspace}/users/${userId}`);
  }

  deactivateWorkspace(currentWorkspace) {
    return this.put(`/workspaces/${currentWorkspace}/deactivate`, '')
  }

  editWorkspace(currentWorkspace, data) {
    const formData = new FormData();

    if (!!data.avatar?.type) {
      formData.append('file', data.avatar)
    }
    formData.set('name', data.name);
    formData.set('code', data.code);
    return this.post(`/workspaces/${currentWorkspace}/update`, formData)
  }

  getTransactionsHistory(currentWorkspace) {
    return this.get(`/workspaces/${currentWorkspace}/transactions`)
  }

  updateGdprForm(workspaceId,workspaceUserId,payload){
      return this.put(`/workspaces/${workspaceId}/users/${workspaceUserId}`,payload);
  }

  changeWorkspace(workspaceId, componentPath) {
    let componentsDict = {
      "candidates": "CANDIDATES",
      "jobs": "JOB",
      "license": "LICENSE",
      "media": "MEDIA",
      "manage": "WORKSPACE_MANAGE"
    }
    const ws = this.authService?.getUser()?.workspaces.find(x => x?.workspace_id == workspaceId);
    if (ws) {
      const index = this.authService.getUser().workspaces.findIndex(wrs => wrs.id == ws.id);
      this.authService.setUserWorkspace(index);
      let workSpaceUser = this.authService.getWorkspaceUser();
      let userHasPermissionForPath = this.authService.getWorkspaceUser().permissions.some(permission => permission?.permission_code?.name == componentsDict[componentPath]);
      if (!userHasPermissionForPath) {
        let currentWorkspace = this.authService.getCurrentWorkspace();
        if (componentPath != "manage" || workSpaceUser.user_id != workSpaceUser?.workspace?.owner_user_id) {
          this.router.navigateByUrl('/redirect', {skipLocationChange: true}).then(() => this.router.navigate([`/admin/workspaces/${currentWorkspace?.id}/${currentWorkspace?.active ? 'dashboard' : 'license/upgrade'}`]));
        }
      }


    } else {
      let currentWorkspace = this.authService.getCurrentWorkspace();
      this.router.navigateByUrl('/redirect', {skipLocationChange: true}).then(() => this.router.navigate([`/admin/workspaces/${currentWorkspace?.id}/${currentWorkspace?.active ? 'dashboard' : 'license/upgrade'}`]));
    }
  }
}
