import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AbstractStore} from './abstract.store';

@Injectable({
  providedIn: 'root'
})
export class Store extends AbstractStore{

  constructor() {
    super({
      skills: null,
      sub_skills:null,
      user: null,
      countries: null,
      packages: null,
      workspace_index: null,
    });
  }
}




