import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  constructor(protected http: HttpClient) {
    super(http);
  }


  updateUser(userId: number, payload: any, silent = false): Observable<any> {
    let options = {};
    if (silent) {
      options = this.silentOption();
    }
    return this.put(`/users/${userId}`, payload, options);
  }

  resetPassword(userId: number, payload: any): Observable<any> {
    return this.put(`/users/${userId}/change-password`, payload);
  }

  deactivateUser(): Observable<any> {
    return this.post(`/users/deactivate`, '');
  }

  deleteUser(userId: number): Observable<any> {
    return this.delete(`/users/${userId}`);
  }

  getAllUsers() {
    return this.get('/users');
  }

  getUser(userId: number) {
    return this.get(`/users/${userId}`);
  }

  assignVoucher(voucherId: number, workspaceIds: any): Observable<any> {
    const payload = {
      data: workspaceIds
    }
    return this.post(`/vouchers/${voucherId}/users`, payload);
  }

  freeTrial() {
    return this.post(`/users/free-trail`, {});
  }

  getPermissionCodes() {
    return this.get('/permission-codes');
  }

  changeUserPermission(payload) {
    return this.post(`/users/assign-user-permission`, payload);
  }

  uncheckUserPermission(userId, permissionCode) {
    return this.delete(`/users/user-permission?user_id=${userId}&permission_code_id=${permissionCode}`);
  }

  addUserPermission(userId, payload) {
    return this.post(`/users/${userId}/permissions`, payload);
  }

  deleteUserPermission(userId, permissionCodeId, workspaceUserId?) {
    let url = `/users/${userId}/permissions/${permissionCodeId}`;

    if (workspaceUserId) {
      url += `?workspace_user_id=${workspaceUserId}`
    }
    return this.delete(url);
  }

  changeUserRole(userId, roleId) {
    return this.post(`/users/${userId}/roles/${roleId}`, {});
  }


}
