import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loom-card',
  templateUrl: './loom-card.component.html',
  styleUrls: ['./loom-card.component.scss']
})
export class LoomCardComponent implements OnInit {

  @Input('cardTitle') cardTitle: string;
  @Input('showThumbnail') showThumbnail: boolean;
  @Input('displayAsCard') displayAsCard: boolean;
  @Input('loomEmbedCodeLink') loomEmbedCodeLink: string;

  sanitizedLoomUrl: any;
  loomModal: NgbModalRef;

  constructor(public translate: TranslateService,
              private modalService: NgbModal,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.sanitizedLoomUrl = this.sanitizeUrl(this.loomEmbedCodeLink)
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openLoomVideoModal(modal, openModal: boolean) {
    if (openModal) {
      this.loomModal = this.modalService.open(modal, {size: 'lg', centered: true});
    }
  }

}
