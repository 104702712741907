import {Injectable, Provider} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {SnotifyService} from 'ng-snotify';
import {TranslateService} from '@ngx-translate/core';
import {catchError, tap} from 'rxjs/operators';
import {Location} from '@angular/common';

@Injectable()
export class InboundInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private messageService: SnotifyService,
    private translate: TranslateService,
    private location: Location
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (req.headers.get('X-Silent') !== 'true' && ((event.status === 200 && req.method === 'PUT') || (event.status === 201 && req.method === 'POST'))) {
              // this.messageService.success('Resource ' + (req.method === 'PUT' ? 'updated' : 'added') + ' successfully');
            }

          }
        }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !req.url.endsWith('/auth/login')) {
            this.authService.logout();
            let params = {};
            if (this.location.path() !== '/') {
              params = {queryParams: {callback: this.location.path()}};
            }
            this.router.navigate(['/auth/login'], params);
          }

          if (req.headers.get('X-Silent') !== 'true' && (err.status >= 500 || err.status == 428)) {
            this.messageService.error(err.error.message || this.translate.get('Something went wrong'));
          }
        }
        return throwError(err);
      })
    );
  }
}

export const inboundInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: InboundInterceptor,
  multi: true
};
