<div class="modal-header text-white text-center">
  <div class="modal-title modal-title w-100 text-uppercase">
    <h4>{{'manager_share_job' | translate}}</h4>
  </div>
  <a class="close-auth pointer" aria-label="Close" (click)="onClose()">
    <img src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body p-3">
  <div>
    <h5 class="font-weight-bold ml-2">{{job?.title}}</h5>
  </div>
  <form [formGroup]="jobShareForm">
    <div class="d-flex job-share-form">
      <div class="w-100">
        <input type="text" class="form-control" formControlName="email"
               placeholder="{{'manager_email' | translate }}" aria-label="" aria-describedby="basic-addon1">
        <span class="error" *ngIf="!!errorText">{{errorExternalShare }}</span>
      </div>
      <div class="d-flex w-100" style="column-gap: 10px;">
        <div class="w-100">
          <select class="form-control active-width" formControlName="role">
            <option value="follower">{{'Follower' | translate}}</option>
            <option value="recruiter">{{'Recruiter' | translate}}</option>
            <option value="owner">{{'Owner' | translate}}</option>
            <option value="external">{{'manager_job_share_external' |translate}}</option>
          </select>
        </div>
        <button class="btn btn-secondary" (click)="shareJobWithUser()" style="white-space: nowrap;">
          <i class="bi bi-share-fill" style="font-size: 13px;"></i>
          <span> {{'manager_share' | translate}}</span>
        </button>
      </div>
    </div>
  </form>
  <table class="table table-responsive table-borderless job-share">
    <thead>
    <tr>
      <th scope="col" [width]="20" class="text-muted">#</th>
      <th scope="col" [width]="150" class="text-muted">{{'manager_settings_first_name' | translate}}</th>
      <th scope="col" [width]="150" class="text-muted">{{'manager_settings_last_time' | translate}}</th>
      <th scope="col" [width]="150" class="text-muted">{{'manager_email' | translate}}</th>
      <th scope="col" [width]="150" class="text-muted">{{'manager_role' | translate}}</th>
      <th scope="col" [width]="150" class="text-muted">{{'manager_job_share_status' | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of jobUsers; index as i;" style="color: rgba(0, 0, 0, 0.75);">
      <td>
        <div class="d-block">{{i + 1}}</div>
      </td>
      <td>
        <div class="d-block">{{user.user?.first_name || '-'}}</div>
      </td>
      <td>
        <div class="d-block">{{user.user?.last_name || '-'}}</div>
      </td>
      <td>
        <div class="d-block">{{user.user?.email || '-'}}</div>
      </td>
      <td>
        <div class="d-block">{{user.pivot?.role || '-'}}</div>
      </td>
      <td>
        <div class="d-block">{{'manager_' + user.pivot?.status || '-' | translate}}</div>
      </td>
      <td>
        <div class="d-block pointer">
          <i (click)="removeUserFromJob(user, i)" class="bi bi-trash"></i>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="externalUsers?.length > 0">
    <hr/>
    <h5>{{'manager_external_users'| translate}}</h5>
    <table class="table table-borderless table-responsive job-share">
      <thead>
      <tr style="padding-top: 14px;">
        <th scope="col" [width]="20" class="text-muted">#</th>
        <th scope="col" [width]="200" class="text-muted">{{'manager_created_at_external' | translate}}</th>
        <th scope="col" [width]="150" class="text-muted">{{'manager_email' | translate}}</th>
        <th scope="col" [width]="150" class="text-muted">{{'manager_external_status'| translate}}</th>
        <th scope="col" [width]="200" class="text-muted"></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of externalUsers; index as i;" style="color: rgba(0, 0, 0, 0.75);">
        <td>
          <div class="d-block">{{i + 1}}</div>
        </td>
        <td>
          <div class="d-block">{{user?.created_at | date:'MM-dd-YYYY'}}</div>
        </td>
        <td>
          <div class="d-block">{{user?.email || '-'}}</div>
        </td>
        <td>
          <div class="d-block">{{'manager_' + user.status + '_external' | translate }}</div>
        </td>
        <td>
          <button *ngIf="user.status == 'expired'" class="btn btn-link p-0"
                  (click)="resendLink(user);"
                  style="margin-top: -4px;">
                  <i class="bi bi-share-fill" style="font-size: 13px;"></i>
            <span class="ml-1">{{'manager_resend_link_external' | translate}}</span>
            <div *ngIf="user.isResending" class="spinner-border text-primary spinner-border-sm text-info" role="status">
            </div>
          </button>
        </td>
        <td>
          <div class="d-block pointer">
            <i (click)="removeExternalUserFromJob(user, i)" class="bi bi-trash"></i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>


