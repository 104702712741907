import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {UserResolver} from './user.resolver';
import {AuthService} from './auth.service';
import {of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Store} from './store';

@Injectable({
  providedIn: 'root'
})
export class LicenseService extends AbstractService {

  constructor(protected http: HttpClient,
              private authService: AuthService,
              private store: Store
  ) {
    super(http);
  }

  getPackages() {
    if (this.store.has('packages')) {
      return of(this.store.getStore('packages'));
    }
    return this.get('/packages').pipe(tap(data => this.store.setStore('packages', data)));
  }

  getPackagesByCurrentWorkspace() {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/packages`);
  }
  
  trackPackageClick(packageId) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/track-package`, {
      workspace_id: this.authService.getCurrentWorkspaceId(),
      package_id: packageId,
      session_id: sessionStorage.getItem("session_id") ? sessionStorage.getItem("session_id") : null
    });
  }

  getUserActivePackage() {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/active-package`);
  }

  publishJob(jobId, transactionId) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/publish`, {transaction_id: transactionId});
  }

  purchasePackagePublishJob(jobId, packageId, invoiceData) {
    return jobId ? this.publishWithPurchase(jobId, packageId, invoiceData) : this.purchasePackage(packageId, invoiceData);
  }

  publishWithPurchase(jobId, packageId, invoiceData) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/jobs/${jobId}/publish-with-purchase/${packageId}`, invoiceData);
  }

  purchasePackage(packageId, invoiceData) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/packages/${packageId}/purchase`, invoiceData);
  }

  downloadInvoice(id) {
    return this.get(`/workspaces/${this.authService.getCurrentWorkspaceId()}/transactions/${id}/invoices`, {responseType: 'blob'});
  }

  getAllLicenses() {
    return this.get(`/licenses`);
  }

  autoRenewalLicense(id) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/licenses/${id}/auto-renewal`, {});
  }

  extendSingleLicense(id, payload) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/licenses/${id}/extend`, payload);
  }

  upgradeLicense(transactionId, packageId, payload) {
    return this.put(`/workspaces/${this.authService.getCurrentWorkspaceId()}/licenses/${transactionId}/upgrade/${packageId}`, payload);
  }

  activateFreeTrialLicense() {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/license/activate-free-trail`, '')
  }

  messageToEnterprise(message) {
    return this.post(`/workspaces/${this.authService.getCurrentWorkspaceId()}/license/request-enterprise-license`, {message: message})
  }

}
