// @ts-ignore
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
// @ts-ignore
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {routes} from './app-routing.module';
import {MainLayoutComponent} from './@LogOn/layouts/main-layout/main-layout.component';
import {AuthLayoutComponent} from './@LogOn/layouts/auth-layout/auth-layout.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {inboundInterceptorProvider} from './@LogOn/interceptors/inbound.interceptor';
import {outboundInterceptorProvider} from './@LogOn/interceptors/outbound.interceptor';
import {AdminLayoutComponent} from './@LogOn/layouts/admin-layout/admin-layout.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {DialogModule} from './@LogOn/components/dialog/dialog.module';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {ContactComponent} from './@LogOn/components/contact/contact.component';
import {NotFoundModule} from './@LogOn/components/not-found/not-found.module';
import {DirectivesModule} from './@LogOn/directives/directives.module';
import {DelegateEmailComponent} from './@LogOn/components/delegate-email/delegate-email.component';
import {GridsterModule} from 'angular-gridster2';
import {UserInviteComponent} from './@LogOn/components/user-invite/user-invite.component';
import {AddWorkspaceComponent} from './@LogOn/components/add-workspace/add-workspace.component';
import {environment} from '../environments/environment';
import {UserWorkspacesComponent} from './@LogOn/components/user-workspaces/user-workspaces.component';
import {JobShareComponent} from './@LogOn/components/job-share/job-share.component';
import {CandidateListComponent} from './@LogOn/components/candidate-list/candidate-list.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {SharedCanditateComponent} from "./landing/shared-canditate/shared-canditate.component";
import { SharedModule } from './shared/shared.module';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#5223CA',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#0c9619',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-out',
};

const languageNamespaces: string[] = ['manager', 'packages', 'general', 'auth', 'skills', 'sub-skills', 'voucher', 'benefits'];

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    AdminLayoutComponent,
    ContactComponent,
    DelegateEmailComponent,
    UserInviteComponent,
    AddWorkspaceComponent,
    UserWorkspacesComponent,
    JobShareComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxSpinnerModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FormsModule,
    SnotifyModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AngularSvgIconModule,
    DialogModule,
    NotFoundModule,
    DirectivesModule,
    GridsterModule,
    NgSelectModule,
    SharedModule
  ],

  providers: [inboundInterceptorProvider, outboundInterceptorProvider, {
    provide: 'SnotifyToastConfig',
    useValue: ToastDefaults
  }, SnotifyService],
  exports: [ContactComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['de', 'de-informal', 'en', 'cs', 'pl', 'uk']);
    translate.setDefaultLang('de');

    const language = localStorage.getItem('language');
    if (language) {
      translate.use(language);
    }
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.api_url + `/translations/ui?group=${languageNamespaces.join(',')}&language=`, ' ');
}
