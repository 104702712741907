import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../services/common.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Store} from '../../services/store';
import {UserWorkspaceService} from '../../services/user-workspace.service';
import {AuthService} from '../../services/auth.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss']
})
export class UserInviteComponent implements OnInit {

  @Output() dataEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  workspaceId;
  price = environment.price;
  inviteForm: FormGroup;
  errors = [];

  invitationSuccessfullySent: boolean;
  invitationFailed: boolean;

  constructor(private activeModal: NgbActiveModal,
              private _fb: FormBuilder,
              public userWorkspace: UserWorkspaceService,
              public _authService: AuthService,
              private _store: Store,
              private loader: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.workspaceId = this._authService.getCurrentWorkspaceId();
    this.inviteForm = this._fb.group({
      email: [null, Validators.required]
    })


  }

  sendToEmail() {
    this.errors = [];
    if (!this.inviteForm.get('email').value) return;

    this.inviteForm.get('email').setErrors(null);
    this.loader.show();
    const payload = {
      user_email: this.inviteForm.get('email').value,
      workspace_id: this.workspaceId
    }
    this.userWorkspace.inviteToWorkspace(this.workspaceId, payload).subscribe(data => {
      this.loader.hide();
      this.invitationSuccessfullySent = true;
      this.sendDataToParent(data);
    }, error => {
      this.loader.hide();
      if (error.status == 400) {
        for (const i in error.error.data) {
          this.errors.push(error.error.data[i])
        }
      } else if (error.status == 409) {
        this.errors.push('User already invited');
      } else {
        this.invitationFailed = true;
      }
      setTimeout(() => this.errors = [], 4000);
      setTimeout(() => this.invitationFailed = false, 6000);
    });
  }

  sendDataToParent(data: any) {
    this.dataEmitter.emit(data);
  }

  onClose() {
    this.activeModal.close();
  }
}
